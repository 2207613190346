<template>
  <div class="c-card-group">

    <div class="c-card">
      <div style="top: 18px; right: 18px;" class="c-card-right-btn"
        :class="{ 'unclickable disabled-overlay': (!currentUser?.AccountHolder || !accountHolder?.SaasOperator) }">
        <UserDetails source="accountholder" :item="accountHolder" />
      </div>
      <!-- <IconDetails style="top: 18px; right: 18px;" class="c-card-right-btn" /> -->
      <div class="c-card-column-3">
        <div class="c-card-item">
          <div class="c-card-title"><span :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">Account
              Holder</span></div>
          <div class="c-card-content content-type-1">
            <p :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">{{ `${accountHolder?.FirstName ?? "Account"}
              ${accountHolder?.LastName ?? "Holder"}` }}</p>
            <div class="btn-group" v-if="currentUser?.AccountHolder">
              <router-link to="/account" class="nav-link d-flex align-items-center">
                <button type="button" class="btn-outline">Usage</button>
              </router-link>
              <button type="button" @click="openBilling" class="btn-outline"
                :class="{ 'disabled-overlay': !isPaymentMethodAdded }">Billing</button>
            </div>
          </div>
        </div>
        <div class="c-card-item">
          <div class="c-card-title"><span>Admin</span></div>
          <div class="c-card-content content-type-1">
            <ul>
              <li>• Manages Operators</li>
              <li>• Views All Activity</li>
              <li>• Views Analytics</li>
            </ul>
            <div class="switch-group" :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">
              <SwitchLg v-model="accountHolder.SaasAdmin" @update:modelValue="toggleAccountHolderRole(true)" />
            </div>
          </div>
        </div>
        <div class="c-card-item">
          <div class="c-card-title">
            <span :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">Operator</span>
          </div>
          <div class="c-card-content content-type-1">
            <ul :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">
              <li>• Manages Contacts</li>
              <li>• Sets Geofence Messages</li>
              <li>• Views Receiver Activity</li>
            </ul>
            <div class="switch-group" :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">
              <SwitchLg v-model="accountHolder.SaasOperator" @update:modelValue="toggleAccountHolderRole(false)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-card">

      <!-- Settings icon to be enabled later -->
      <!-- <IconSettings @click="showSettingsModal = true" style="top: 18px; right: 22px;" class="c-card-right-btn" /> -->
      <div class="c-card-item">
        <div class="c-card-title">Billed Users</div>
        <div class="c-card-content content-type-2">
          <div class="c-content-row">
            <div>Admin</div>
            <div class="c-value">{{ totalAdmins }}</div>
          </div>
          <div class="c-content-row">
            <div>Operator</div>
            <div class="c-value">{{ totalOperators }}</div>
          </div>
          <div class="c-card-column-2">
            <div></div>
            <div class="c-content-row">
              <div>Receivers</div>
              <div class="c-value">{{ totalReceivers }}</div>
            </div>
          </div>
          <div class="c-card-column-2 c-bottom-content">
            <div>
              <button type="button" class="btn-blue-filled" @click="ToggleAddUserPopup('buttonTrigger')">Add
                User</button>
            </div>
            <div class="c-content-row">
              <div>Total</div>
              <div class="c-value">{{ totalAdmins + totalOperators + totalReceivers }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="align-center justify-center admin-table">

    <div v-if="successMessage" class="message-delete-alert-con">
      <div class="alert alert-dismissible alert-success">
        <button type="button" class="close" @click="hideUserAddedSuccess" style="color:#828282">&times;</button>
        <span class="alert-headeing">Confirmation</span><br>
        {{ successMessage }}
      </div>
    </div>

    <div v-if="errorMessage" class="message-delete-alert-con">
      <div class="alert alert-dismissible alert-danger">
        <button type="button" class="close" @click="hideErrorMessage" style="color:#828282">&times;</button>
        <span class="alert-headeing">Error</span><br>
        {{ errorMessage }}
      </div>
    </div>

    <S2gTable @refresh="loadUsers" 
      @toggle-row-select="selectMessages"
      @select-all="selectAll"
      :indeterminate="indeterminate" :items="filteredUser"
      :selectedItemCount="selectedContacts" ref="tableRef" :isWhiteIcons="true" :headers="contactheader" 
      :filterItems="[
        { label: 'All', value: 'all' },
        { label: 'None', value: 'none' },
      ]"
       :thsearch="false" :showCount="true" :isReload="true"
      :totalItems="users.length ?? 0" :totalCountText="'Users'" :totalCountText1="'User'" :dynamicSearchHeight="false"
      :isSelectGlobalCheckBox="isSelectAll" :page="0" @column-filter="handleFilter">
      <!-- @column-filter="handleFilter" -->

      <template v-slot:table_body="{ item }">
        <td>
          <div>{{ item.item.name }}</div>
        </td>
        <td>
          <CheckCircle v-if="item.item.admin" />
          <!-- <SwitchSm v-model="item.item.admin" @update:modelValue="toggleAdminRole(item)" /> -->
        </td>
        <td>
          <CheckCircle v-if="item.item.operator" />
          <!-- <SwitchSm v-model="item.item.operator" @update:modelValue="toggleOperatorRole(item)" /> -->
        </td>
        <td>
          <div style="width: 70px;" class="text-right">{{ item.item.receivers }}</div>
        </td>
        <td style="max-width: 150px; width: 150px">
          <div class="filter-cell" v-if="item.item.status == 'INVITED'">
            <ContactPending /> <span class="status-text">pending...</span>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'ACCEPTED'">
            <ContactVerified /> <span class="status-text">active</span>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'EXPIRE'">
            <UserPlus />
            <button @click="reinviteUser(item.item?.user)">
              <div class="invite-button">
                invite
              </div>
            </button>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'NO ROLE'">
            <NavmenuProfileIcon /> <span class="status-text">no role</span>
          </div>
        </td>
        <td style="max-width: 150px; width: 150px">
          <div class="table-actions" v-if="item.item.id != undefined">
            <span v-if="item?.item?.user?.SaasOperator">
              <UserDetails @error="showError" :item="item.item?.user" />
            </span>
            <span class="icon-btn">
              <button @click="editUser(item?.item?.user)">
                <EditIcon />
              </button>
            </span>
            <span class="icon-btn" :class="{ 'disabled-overlay': currentUser?.UserID == item?.item?.id }">
              <button @click="currentUser?.UserID == item?.item?.id ? null : (deleteConfirmUser = item.item)">
                <IconArchieve />
              </button>
            </span>
          </div>
          <div class="table-actions" v-if="item.item?.user?.UserInvitationID">
            <span class="icon-btn">
              <button @click="deleteConfirmInvitation = item.item">
                <DeleteIcon />
              </button>
            </span>
          </div>
        </td>
      </template>
    </S2gTable>
  </div>

  <AddUser v-if="addUserPopup.buttonTrigger" @close="editingUser = null" :source="editingUser ? 'edit' : 'add'"
    :editUser="editingUser" :title="addUserModalTitle" @success="showSuccess" @error="showError" @freetrialerror="showFreeTrialError"
    :TogglePopup="() => ToggleAddUserPopup('buttonTrigger')">
  </AddUser>

  <OneOperatorAlert v-model="atLeastOneOperatorWarning" :close="closeOperatorAlert" />
  <OneAdminAlert v-model="atLeastOneAdminWarning" :close="closeAdminAlert" />

  <Confirmation v-model="deleteConfirmUser" :data="deleteConfirmUser" @closed="deleteConfirmUser = null"
    :onconfirm="(data) => deleteUser(data)" :body="'Do you really want to archive this user?'" />

  <Confirmation v-model="deleteConfirmInvitation" :data="deleteConfirmInvitation"
    @closed="deleteConfirmInvitation = null" :onconfirm="(data) => deleteInvitation(data)"
    :body="'Do you really want to delete this invitation?'" />

    <!-- Settings icon to be enabled later -->
    <!-- <AccountSettingsModal :showSettings="showSettingsModal" @closed="showSettingsModal = false" /> -->

  <AlertDialog v-model="adminFreeTrialError" @closed="adminFreeTrialError = false" title="Attention">
    <div>During this period of free usage, you can add up to two Admins.</div> <br>
    <div>To add more than two, activate your paid account now.</div> <br>
    <div>If you are not the Account Holder, request that they convert to a paid account.</div>
  </AlertDialog>

  <AlertDialog v-model="operatorFreeTrialError" @closed="operatorFreeTrialError = false" title="Attention">
    <div>During this period of free usage, you can add up to two Operators.</div> <br>
    <div>To add more than two, activate your paid account now.</div> <br>
    <div>If you are not the Account Holder, request that they convert to a paid account.</div>
  </AlertDialog>

</template>

<script setup>
import SwitchLg from '@/components/common/SwitchLg.vue';
import SwitchSm from '@/components/common/SwitchSm.vue';
import { ref, onMounted, computed, reactive } from 'vue';
import S2gTable from "@/components/common/S2gTable.vue";
import EditIcon from '@/components/icons/EditIcon';
import IconArchieve from '@/components/icons/IconArchieve';
import UserPlus from "@/components/icons/UserPlus.vue";
import ContactPending from "@/components/icons/ContactPending.vue";
import ContactVerified from "@/components/icons/ContactVerified.vue";
import UserDetails from '@/components/common/UserDetails.vue'
import AddUser from '@/components/AddUser.vue';
import userService from '@/services/userService';
import { useStore } from 'vuex';
import Confirmation from '@/components/modals/Confirmation.vue';
import OneOperatorAlert from '@/components/modals/OneOperatorAlert.vue';
import CheckCircle from '@/components/common/CheckCircle.vue';
import OneAdminAlert from '@/components/modals/OneAdminAlert.vue';
import NavmenuProfileIcon from '@/components/icons/NavmenuProfileIcon.vue';
import AccountSettingsModal from '@/components/modals/AccountSettingsModal.vue';
import IconSettings from '@/components/icons/IconSettings.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import IconDetails from '@/components/icons/IconDetails.vue';
import AlertDialog from '@/components/modals/AlertDialog.vue';
import { manageSelectedItems, manageSingleChecked, manageUnSelectedItems } from '@/helpers/s2gtable-helper';


const adminRoleSwitch = ref(false);
const operatorRoleSwitch = ref(false);
let successMessage = ref(null);
let errorMessage = ref(null);
let editingUser = ref(null);
let totalAdmins = ref(0);
let totalOperators = ref(0);
let totalReceivers = ref(0);
let users = ref([]);
let filteredUser = ref([]);
let operators = [];
let accountHolder = reactive({});
let deleteConfirmUser = ref(null);
let deleteConfirmInvitation = ref(null);
let atLeastOneOperatorWarning = ref(false);
let atLeastOneAdminWarning = ref(false);
const showSettingsModal = ref(false);
const isPaymentMethodAdded = ref(false);
const adminFreeTrialError = ref(false);
const operatorFreeTrialError = ref(false);
const selectedMessages = ref([]);
const isSelectAll = ref(false);
const pager = ref({});

let store = useStore();
let currentUser = ref(store.getters.user);

const addUserModalTitle = computed(() => {
  if (editingUser.value?.UserInvitationID) {
    return "Edit Invitation";
  } else if (editingUser.value) {
    return "User"
  } else {
    return "New User"
  }
});


const addUserPopup = ref({
  buttonTrigger: false
});
const ToggleAddUserPopup = (trigger) => {
  addUserPopup.value[trigger] = !addUserPopup.value[trigger]
}

const handleFilter = (e) => {
  if (e?.column?.key == 'status') {
    if (e.e.value == 'all') {
      filteredUser.value = users.value;
    } else {
      filteredUser.value = users.value.filter(u => u.status == e?.e?.value);
    }
  }
};

const contactheader = [
  { key: "name", title: "Name", },
  { key: "admin", title: "Admin" },
  { key: "operator", title: "Operator" },
  { key: "receivers", title: "Receivers" },
  {
    key: "status",
    title: "Status",
    class: "pr-0",
    isFilter: true,
    filterItems: [
      { label: 'All', value: 'all' },
      { label: 'Pending', value: 'INVITED' },
      { label: 'Active', value: 'ACCEPTED' },
      { label: 'Expired', value: 'EXPIRED' },
    ],
  },
  { key: "details", title: "Details" },
]
let indeterminate = false;

function selectMembers(e) {
}

function toggleProfilePopup() {
  dispatchEvent(new CustomEvent("toggleProfilePopup"))
}

async function editUser(user) {
  editingUser.value = user;
  // editingUser.value = await userService.getUserById(user.UserID);
  ToggleAddUserPopup("buttonTrigger");
}

const loadUsers = async () => {
  let fetchedUsers = await userService.getAllUsers();
  console.log(fetchedUsers)
  users.value = fetchedUsers.map(user => {
    return {
      id: user.UserID,
      name: `${user.FirstName} ${user.LastName}`,
      admin: !!user.SaasAdmin,
      operator: !!user.SaasOperator,
      receivers: user.receiver,
      status: user.Status,
      user: user
    }
  })
  filteredUser.value = users.value;
  operators = await userService.getFilteredOperators();
}

const loadUsersCount = async () => {
  let data = await userService.getUsersCount();
  totalAdmins.value = data.admin;
  totalOperators.value = data.operator;
  totalReceivers.value = data.receiver;
};

const showSuccess = (message, data) => {
  hideErrorMessage();
  editingUser.value = null
  if (data?.refresh) {
    loadUsers();
    loadUsersCount();
  }
  if (message) {
    successMessage.value = message;
    setTimeout(() => {
      hideSuccessMessage()
    }, 5000);
  }
}

const showError = (message) => {
  hideSuccessMessage();
  errorMessage.value = message;
  setTimeout(() => {
    hideErrorMessage()
  }, 5000);
}

const deleteUser = async (user) => {
  let userId = user.id;
  try {
    let res = await userService.deleteUser(userId);
    // showSuccess(res?.message);
  } catch (error) {
    // showError(error?.message);
  }
  deleteConfirmUser.value = null;
}

const deleteInvitation = async (user) => {
  let invitationId = user?.user?.UserInvitationID;
  try {
    let res = await userService.deleteInvitation(invitationId);
    // showSuccess(res?.message);
  } catch (error) {
    // showError(error?.message);
  }
  deleteConfirmInvitation.value = null;
  loadUsers();
}

const updateUser = async (user, isAdmin) => {
  try {
    if (!Object.hasOwn(user, 'timezone')) {
      user.timezone = user.TimeZone;
    }
    console.log(user);
    let phone = user?.Phone ?? user?.PhoneNumber;
    let res = await userService.updateUser(user.FirstName,
      user.LastName, user.Email,
      phone, !!user.SaasAdmin, !!user.SaasOperator, user?.timezone, user?.UserID);
    // showSuccess(res?.message);
    if (user.UserID == currentUser.value?.UserID) {
      store.commit("setUser", user);
    }
    return null;
  } catch (error) {
    return error;
  }
}

const updateInvitation = async (user) => {
  try {
    let res = await userService.updateInvitation(user.FirstName,
      user.LastName, user.Email,
      user.Phone, !!user.SaasAdmin, !!user.SaasOperator, user?.UserInvitationID);
    // showSuccess(res?.message);
    return true;
  } catch (error) {
    // showError(error?.message);
    return false;
  }
}

const hideSuccessMessage = () => {
  successMessage.value = false;
}
const hideErrorMessage = () => {
  errorMessage.value = false;
}

const toggleAccountHolderRole = async (isAdmin) => {
  let error = await updateUser(accountHolder);
  if (error) {
    let isFreeTrialError = showFreeTrialError(error);
    if (isAdmin) {
      accountHolder.SaasAdmin = !accountHolder.SaasAdmin;
      atLeastOneAdminWarning.value = !isFreeTrialError;
    } else {
      accountHolder.SaasOperator = !accountHolder.SaasOperator;
      atLeastOneOperatorWarning.value = !isFreeTrialError;
    }
  }
}

function closeOperatorAlert() {
  atLeastOneOperatorWarning.value = false;
}

function closeAdminAlert() {
  atLeastOneAdminWarning.value = false;
}

const reinviteUser = async (user) => {
  try {
    let res = await userService.addUser(user.FirstName,
      user.LastName, user.Email,
      user.Phone, !!user.SaasAdmin, !!user.SaasOperator, user?.UserInvitationID);
    // showSuccess(res?.message);
    return true;
  } catch (error) {
    // showError(error?.message);
    return false;
  }
}

const loadAccountHolder = async () => {
  let user = await userService.getAccountHolder();
  Object.assign(accountHolder, user);
  adminRoleSwitch.value = accountHolder?.SaasAdmin;
  operatorRoleSwitch.value = accountHolder?.SaasOperator;
}

async function openBilling() {
  let invoice = await userService.getBillingUrl();

  if (invoice && invoice.data) {
    window.open(invoice.data, '_blank');
  } else {
    console.error('Invoice data is unavailable.');
  }
}

async function loadPaymentMethodStatus() {
  let res = await userService.getFreeTrialStatus();
  isPaymentMethodAdded.value = res?.isPaymentMethod
}

function showFreeTrialError(res) {
  if (res?.type == 'FREE_TRIAL') {
    if (res?.code == 'admin') adminFreeTrialError.value = true;
    else if (res?.code == 'operator') operatorFreeTrialError.value = true;
    return true;
  }
  return false;
}


function selectAll(event) {
      // this.searchVisible = false;
      isSelectAll.value = event.target.checked;
      users.value = users.value.map((e) => {
        e.checked = event.target.checked;
        e.checkdClass = "";
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        return e;
      });
      // this.uncheckedMessages = [];
      // this.checkedMessages = [];
      // if (this.isSelectAll) {
      //   this.selectedMessages = this.pager.totalItems;
      //   this.checkedMessages = [];
      //   this.uncheckedMessages = [];
      //   this.indeterminate = false;
      // } else {
      //   this.selectedMessages = 0;
      //   this.indeterminate = false;
      }


function selectMessages(e) {
    const event = e.e;
    console.log(e.item);
    // this.indeterminate = false;
    // this.searchVisible = false;
    const u = manageSingleChecked(
      event,
      e.item,
      "ContentID",
      users.value,
      selectedMessages.value,
      isSelectAll.value,
      pager.value?.totalItems
    );
    console.log(u);
    this.selectedMessages = Number(u.selectedCounts);
    this.isSelectAll = u.isSelectAll;
    this.indeterminate = u.indeterminate;
    if (event.target.checked) {
      e.item.checked = event.target.checked;
    } else {
      // if (this.isSelectAll) {
      //   this.indeterminate = true;
      // }
    }
    
    manageSelectedItems(event, e.item, "ContentID", this.checkedMessages);
    manageUnSelectedItems(event, e.item, "ContentID", this.uncheckedMessages);
    manageGlobalSelection();
    this.ourcontents = this.ourcontents.map((e) => {
      e.checkdClass = "";
      if (e.checked) {
        e.checkdClass = "member_selected";
      }
      e.class = "hover_item";
      return e;
    });
  }

  function manageGlobalSelection() {
      //const checkedCount = this.users.filter((e) => e.checked).length;

      if (this.selectedMessages > 0) {
        if (this.selectedMessages == this.pager.totalItems) {
          this.isSelectAll = true;
          this.selectedMessages = this.pager.totalItems;
          this.indeterminate = false;
        } else if (!this.isSelectAll) {
          this.indeterminate = true;
        }
      } else {
        this.indeterminate = false;
        this.isSelectAll = false;
      }
    }


onMounted(() => {
  loadUsers();
  loadUsersCount();
  loadAccountHolder();
  loadPaymentMethodStatus();
});


</script>

<style lang="scss" scoped>
.c-card-right-btn {
  position: absolute;
}

.alert-dismissible {
  margin-bottom: 12px;
}


.c-card-group {
  display: grid;
  grid-template-columns: 1fr 440px;
  column-gap: 60px;
}

.c-card-column-4 {
  display: grid;
  grid-template-columns: 266fr 272fr 202fr auto;
}

.c-card-column-3 {
  display: grid;
  grid-template-columns: 266fr 272fr 202fr;
}

.c-card-column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.content-type-1 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;

  & .btn-group {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: 85px;
  }
}

.c-content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #E0E0E0;
  line-height: 20px;

  & .c-value {
    font-size: 24px;
    line-height: 20px;
  }
}

.c-bottom-content {
  margin-top: auto;

  & .c-content-row {
    height: 36px;
    border-bottom: none;
  }
}

.switch-group {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.c-card {
  background-color: #fff;
  padding: 33px 30px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  & .c-card-title {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    padding-bottom: 16px;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
  }

  & .c-card-item {
    display: flex;
    flex-direction: column;
    flex: 1;

    & .c-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.btn-outline {
  display: flex;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #7B8794;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #000;
  transition: 0.2s all ease;

  &:hover {
    background-color: #F2F2F2;
  }
}

.btn-blue-filled {
  display: flex;
  min-width: 100px;
  height: 36px;
  align-items: center;
  padding: 0 17px;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  line-height: 16.41px;
  cursor: pointer;
  color: #fff;
  background-color: #4066CF;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s all ease;

  &:hover {
    background-color: #6B87D4;
  }
}

.status-text {
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  font-weight: 500;
}

.admin-table {
  margin-top: 26px;
  margin-bottom: 60px;
}

.invite-button {
  width: 50px;
  height: 20px;
  background: #7B8794;
  font-size: 13px;
  line-height: 16px;
  color: #F2F2F2;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.disabled-overlay {
  pointer-events: none;
  opacity: 0.3;
}

.unclickable {
  pointer-events: none;
}
</style>
