<template>
  <div class="c-card-group">
     <div class="c-card">
       <div class="c-card-column-3">
         <div class="c-card-item">
           <div class="c-card-title px-3">
             <div class="c-icon-text">
               <div>Active messages</div>
               <IconRefresh @click="loadActivitySummaryData"/>
             </div>
           </div>
           
           <div class="c-card-block">
            <ActivityGeoMessage/>
             <p class="text-blue">{{ summaryData.active_messages }}</p>
           </div>
         </div>
         <div class="c-card-item text-center">
           <div class="c-card-title px-3 centered">
             <div class="c-icon-text">
               <div>Receivers heard</div>
               <span>
                <IconQuestionMarkSm />
                <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                  <b><i>Active messages</i></b> are the ones set to be heard today.<br> These include messages set prior with no duration limit,<br> or set to repeat. <br><br>
                  <b><i>Receivers heard</i></b> are the individuals who have entered <br>or left a geofenced area, triggering the audio play of a<br> message and hearing it to completion.<br><br>
                  <b><i>Not heard yet</i></b> designates the individuals who are <br> assigned to hear an active message and have not yet<br>  entered or left a geofenced area. 
                </v-tooltip>
                </span>
             </div>
           </div>
           <div class="c-card-block mx-auto">
             <ActivityMessageHeard/>
             <p class="text-green">{{ summaryData.receivers_heard }}</p>
           </div>
         </div>
         <div class="c-card-item text-right">
           <div class="c-card-title px-3">...not yet heard</div>
           <div class="c-card-block ml-auto">
             <ActivityMessageNotHeard />
             <p class="text-navy">{{ summaryData.notyet_heard }}</p>
           </div>
         </div>
       </div>
       <div class="c-arrow-icon">
         <div class="c-icon-text-2">
             <!-- <div>Today - 12:00am</div> -->
             <div>Today - {{ currentAMPM() }}</div>
           </div>
       </div>
     </div>

     <div class="c-card c-card-sm">
       <div class="c-card-item">
         <div class="c-card-column-2">
           <div class="c-card-title">
           <div class="c-icon-text">
             <div>History</div>
                <span>
                  <IconQuestionMarkSm />
                  <v-tooltip
                      activator="parent"
                      location="bottom"
                    >
                    <b><i>History</i></b> is the total count of all messages created that are <br> active, expired, drafted, or deleted. This  also counts the<br> total number of messages that have been heard, and the<br> total number of Receivers.
                  </v-tooltip>
                </span>
             </div>
           </div>
           <div class="c-card-title justify-end flex">
           <div class="c-icon-text-2">
             <!-- <IconArrowDown /> -->
             <div>Total to date</div>
             </div>
           </div>
         </div>
         <div class="c-card-content">
            <div class="c-content-row">
               <div>Geofence Messages</div>
               <div class="c-value">{{ summaryData.geofence_messages }}</div>
            </div>
            <div class="c-content-row">
               <div>Message plays</div>
               <div class="c-value">{{ summaryData.message_plays }}</div>
            </div> 
            <div class="c-content-row">
               <div>Receivers</div>
               <div class="c-value">{{ summaryData.receivers }}</div>
            </div> 
            <div class="c-content-row">
               <div>Communication Efficiency</div>
               <div class="c-value-big">{{ summaryData.communication_efficiency }}%</div>
            </div>
         </div>
       </div>
     </div>
  </div>

  <div class="align-center justify-center activity-table">
     <ListTable
       :indeterminate="indeterminate"
       :items="tableData"
       :selectedItemCount="selectedContacts"
       @search="searchTeams"
       @open-search="searchOpen"
       @refresh="onResetClicked"
       @column-filter="handleFilter"
       ref="tableRef"
       :isWhiteIcons="true"
       :headers="contactheader"
       :filterItems="[
         { label: 'All', value: 'all' },
         { label: 'None', value: 'none' },
       ]"
       
       :thsearch="false"
       :showCount="true"
       :isReload="true"
       :totalItems="receiversCount"
       :totalCountText="'Receivers'"
       :totalCountText1="'Receiver'"
       :dynamicSearchHeight="false"
       :isSelectGlobalCheckBox="isSelectAll"
       :page="(currentPage - 1) * itemsPerPage"
     > 
     
       <template v-slot:table_body="{ item }">
         <td>
           <div>{{ item.item.name }}</div>
         </td>
         <td>
           <div class="filter-cell ml-0" v-if="item.item.status == 'pending' && item.item.icon != 'Contact'"><component :is="item.item.icon"></component> <span>{{item.item.group}}</span></div>
           <div class="filter-cell ml-0" v-if="item.item.status == 'active' && item.item.icon != 'Contact'"><component :is="item.item.icon"></component> <span>{{item.item.group}}</span></div>
         </td>
         <td>
           <div class="fixed-length-text" style="max-width: 242px;">{{ item.item.location }}</div>
         </td>
         <td>
           <div class="fixed-length-text" style="max-width: 234px;">{{ item.item.messages }}</div>
         </td>
         <!-- <td>
           <div class="settings-actions">
             <SettingDots/>
           </div>
         </td> -->
         <td class="last-col">
           <div class="heard-block">
             <div v-if="item.item.isHeard">Heard at {{ item.item.heard }}</div>
             <ActivityRowHeard v-if="item.item.isHeard" />
             <ActivityRowNotHeard v-if="!item.item.isHeard && item.item.notdelivered" />
             <ActivityMessageNotDelivered v-if="!item.item.isHeard && !item.item.notdelivered"/>
           </div>
         </td>
       </template>
     </ListTable>
   </div>
   <Paginaiton v-if="pager.totalPages > 1" 
          :currentPage="currentPage"
          :totalItems="pager.totalItems"
          :itemsPerPage = "itemsPerPage"
          :totalPages = "pager.totalPages"
          @page-changed="changePage"
        />

   
</template>

<script>
import IconQuestionMarkSm from '@/components/icons/IconQuestionMarkSm.vue';
import IconRefresh from '@/components/icons/IconRefresh.vue';
//import IconArrowDown from '@/components/icons/IconArrowDown.vue';
import ActivityGeoMessage from '@/components/icons/activity/Activity-Geo-Message.vue';
import ActivityMessageHeard from '@/components/icons/activity/Activity-Message-heard.vue';
import ActivityMessageNotHeard from '@/components/icons/activity/Activity-Message-not-heard.vue';
import ActivityMessageNotDelivered from '@/components/icons/activity/Activity-Message-not-delivered.vue';
import ListTable from "@/components/common/ListTable.vue";
import ActivityRowHeard from '@/components/icons/ActivityRowHeard';
import ActivityRowNotHeard from '@/components/icons/ActivityRowNotHeard';
//import SettingDots from '@/components/icons/message_settings/SettingDots';
import Paginaiton from '@/components/common/Paginaiton.vue';
import CompanyIcon from "@/components/icons/Company";
import GroupIcon from "@/components/icons/Group";
import EventIcon from "@/components/icons/Event";
import PropertyIcon from "@/components/icons/Property";
import AreaIcon from "@/components/icons/Area";
import TeamIcon from "@/components/icons/MemberIcon";
import ProjectIcon from "@/components/icons/Project";
import LocationIcon from "@/components/icons/Location";
import Contact from "@/components/icons/activity/Contact";

export default {
  name: 'Activity',
  components: {IconQuestionMarkSm, 
              IconRefresh, 
              //IconArrowDown,
              ActivityGeoMessage,
              ActivityMessageHeard, 
              ActivityMessageNotHeard,
              ActivityMessageNotDelivered,
              ListTable,
              ActivityRowHeard,
              ActivityRowNotHeard,
              //SettingDots,
              CompanyIcon,
              GroupIcon,
              EventIcon,
              ProjectIcon,
              PropertyIcon,
              LocationIcon,
              TeamIcon,
              AreaIcon,
              Contact,
              Paginaiton
            },
    data() {
              return {
                contactheader: [
                  { key: "name", title: "Name",},
                  { key: "groups", title: "Groups",},
                  { key: "location", title: "Locations"},
                  { key: "messages", title: "Assigned Messages", 
                    tooltip:true, 
                    tooltiphtml:'<b><i>Assigned Messages</i></b> are all the messages <br>the <b><i>Operator</i></b> has assigned to a <b><i>Contact</i></b> or a <b><i>Group</i></b>.<br><br>If the number of <b><i>Receivers</i></b> is higher than the number of<br><b><i>Active Messages</i></b>, the number of <b><i>Assigned Messages</i></b> is greater.<br><br>The list displays a separate row for each message assigned.<br>A <b><i>Group</i></b> can have more than 1 message assigned to them. <br>The same goes for a <b><i>Contact</i></b>.'},
                  /* { key: "details", title: "Details" }, */
                  {
                    key: "status",
                    title: "Status",
                    class: "pr-0",
                    isFilter: true,
                    filterItems: [
                      { label: 'Heard', value: 'heard' },
                      { label: 'Pending', value: 'pending' },
                      { label: 'Not Delivered', value: 'notdelivered' },
                    ],
                  },
                  
                ],
                summaryData:{},
                tableData: [],
                pager:{},
                receiversCount:0,
                indeterminate : false,
                isSelectAll: false,
                currentPage: 1,
                itemsPerPage: 10,
                statusFilter:'',
                searchString: '',
                searchVisible: false
              }
            },
            mounted(){
              this.loadActivitySummaryData();
              //refresh the data after every 5 minutes
              setInterval(this.loadActivitySummaryData, 60000 * 5)
            },
            methods:{
              loadActivitySummaryData(){
                //alert(1)
                let query = `?UserID=${this.$root.userdetail.user[0].UserID}&Role=${this.$root.userdetail.user[0].Role}&ClientID=${this.$root.userdetail.user[0].ClientID}&QueryTime=${this.getDateForSql(new Date())}`;
                fetch(process.env.VUE_APP_APIDOMAIN + "/getactivitysummary"+query, {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("tknds"),
                },
              })
                .then((response) => response.json())
                .then((result) => {
                  //console.log('summary data::',result );
                  this.summaryData = result;
                });


                //Once the summary data is loaded, load the table data
                this.loadActivityTableData();
              },

              loadActivityTableData(){
                let query = `?UserID=${this.$root.userdetail.user[0].UserID}&Role=${this.$root.userdetail.user[0].Role}&ClientID=${this.$root.userdetail.user[0].ClientID}&QueryTime=${this.getDateForSql(new Date())}&page=${this.currentPage}&perpage=${this.itemsPerPage}&statusFilter=${this.statusFilter}&searchString=${this.searchString}`;
                fetch(process.env.VUE_APP_APIDOMAIN + "/getactivitydata"+query, {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("tknds"),
                },
              })
                .then((response) => response.json())
                .then((result) => {
                  console.log('table data :: ', result)
                  this.pager = result.pager;
                  this.tableData = result.returnData;
                  this.contactheader.find(item => item.key === "messages").title = this.pager.totalItems + " Assigned Messages";
                  this.receiversCount = result.ReceiverCount;
                });
              },

              getDateForSql(dateTime){
                      // Format the Date object to the desired output
                      let year = dateTime.getFullYear();
                      let month = ('0' + (dateTime.getMonth() + 1)).slice(-2); // Months are zero-based
                      let day = ('0' + dateTime.getDate()).slice(-2);
                      let hours = ('0' + dateTime.getHours()).slice(-2);
                      let minutes = ('0' + dateTime.getMinutes()).slice(-2);
                      let seconds = ('0' + dateTime.getSeconds()).slice(-2);
                      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
              },

              currentAMPM(){
                let date = new Date();
                return String(date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })).toLowerCase();
              },

            
            changePage(newPage){
              this.currentPage = newPage;
              this.loadActivityTableData();
            },

            searchOpen(e) {
              this.searchVisible = true;
            },

            searchTeams(e) {
              this.searchString = e;
              this.loadActivityTableData();
            },

            onResetClicked(){
              this.currentPage = 1;
              this.searchString = '';
              this.statusFilter = '';
              this.loadActivityTableData();
            },

            handleFilter(e){
              if(e.column){
                this.currentPage = 1;
                //this.filter.key = e.column.key;
                this.statusFilter = e.e.value;
                this.loadActivityTableData();
              }
             
            }

          }
          

}




</script>

<style lang="scss" scoped>
.c-card-group {
   display: grid;
   grid-template-columns: 1fr 440px;
   column-gap: 60px;
}

.c-card-column-3 {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
}

.c-card-column-2 {
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: center;
}

.content-type-1 {
   font-size: 16px;
   line-height: 20px;
   margin-top: 20px;
   & .btn-group {
       margin-top: 90px;
       display: flex;
       flex-direction: column;
       gap: 22px;
       height: 85px;
   }
 }

 .c-content-row {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 15px 0;
   border-bottom: 1px solid #E0E0E0;
   line-height: 20px;
   &:last-child {
     border-bottom: none;
   }
   & .c-value {
     font-size: 24px;
     line-height: 20px;
     color: #344D7A;
   }
   & .c-value-big {
       font-size: 48px;
       color: #344D7A;
       line-height: 1.2;
   }
 }

 

 .switch-group {
   margin-top: 27px;
 }

.c-card {
   background-color: #fff;
   padding: 27px 30px 20px;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   
   & .c-card-title {
       font-size: 16px;
       line-height: 24px;
       color: #000;
       padding-bottom: 7px;
       border-bottom: 1px solid #E0E0E0;
       font-weight: 500;
       &.centered {
        display: flex;
        justify-content: center;
       }
   }
   &.c-card-sm {
     padding: 28px 30px 0px;
     & .c-card-title { 
       padding-bottom: 7px;
       line-height: 24px;
     }
   }
   & .c-card-item {
     display: flex;
     flex-direction: column;
     flex: 1;
       & .c-card-content {
         display: flex;
         flex-direction: column;
         flex: 1;
       & ul {
         list-style-type: none;
         padding: 0;
         margin: 0;
         display: flex;
         flex-direction: column;
         gap: 10px;
       }
     }
   }
}

.btn-outline {
   display: flex;
   width: 100px;
   height: 30px;
   align-items: center;
   justify-content: center;
   text-align: center;
   border: 1px solid #7B8794;
   border-radius: 5px;
   font-size: 16px;
   line-height: 20px;
   cursor: pointer;
   color: #000;
}
.btn-blue-filled {
   display: flex;
   min-width: 100px;
   height: 36px;
   align-items: center;
   padding: 0 17px;
   justify-content: center;
   text-align: center;
   border-radius: 3px;
   font-size: 14px;
   line-height: 16.41px;
   cursor: pointer;
   color: #fff;
   background-color: #4066CF;
   text-transform: uppercase;
   font-weight: 500;
   transition: 0.2s all ease;
   &:hover {
     background-color: #6B87D4;
   }
}

.status-text {
   font-size: 13px;
   line-height: 16px;
   color: #333333;
   font-weight: 500;
}

.activity-table {
   margin-top: 26px;
   margin-bottom: 30px;
   
 }


.invite-button {
   width: 50px;
   height: 20px;
   background: #7B8794;
   font-size: 13px;
   line-height: 16px;
   color: #F2F2F2;
   font-weight: 500;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
}

.c-icon-text {
   display: flex;
   align-items: center;
   gap: 10px;
 }
 .c-icon-text-2 {
   display: flex;
   align-items: center;
   font-size: 16px;
   line-height: 20px;
 }

 
 .c-card-block {
   width: 140px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 18px 18px 20px;
   gap: 23px;
   & p {
     font-size: 74px;
     line-height: 1;
     font-weight: 300;
     &.text-blue {
       color: #4992E6;
     }
     &.text-green {
       color: #25AC1E;
     }
     &.text-navy {
       color: #344D7A;
     }
   }
 } 


 .c-arrow-icon {
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .heard-block {
   display: flex;
   align-items: center;
   font-size: 14px;
   line-height: 16px;
   gap: 17px;
   color: #333333;
   font-weight: 600;
   white-space: nowrap;
   flex-shrink: 0;
   margin-left: auto;
 }
 .settings-actions {
   display: flex;
   justify-content: center;
 }
 .last-col {
   display: flex;
   justify-content: flex-end;
 }
</style>
