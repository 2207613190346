<template>
    <div class="group_member_dialog">
      <v-dialog
        v-model="dialog"
        max-width="1000"
        width="1000"
        height="800"
        persistent
      >
        <v-card class="group-member__dialog" height="800">
            <button type="button"
              class="close-btn-icon"
              @click="onCloseWithoutSave"
            >
            <span class="outline">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>

            </span>
            <span class="outline_hover">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            </button>

            <div class="group-member__list">
              <v-tabs
              class="custom-btn"
              v-model="tab"
              align-tabs="center"
              color="deep-purple-accent-4"
            >
              <v-tab class="px-0" :value="1">Contacts</v-tab>
              <v-tab class="px-0" :value="2">Groups</v-tab>
              </v-tabs>
              <div class="position-relative selected-member-wrapper">
                <div class="w-100 position-absolute" v-if="!listSearchOpen">
                  <div class="d-flex align-center justify-space-between selected_member_count" :class="{ 'attention-required': !tempGroupData.selectedItem && !tempContactData.selectedItem}">
                    <div v-if="!tempGroupData.selectedItem && !tempContactData.selectedItem" :isClose="false">
                      <p style="text-align: center;"><strong>Attention: </strong>At least 1 Receiver must be selected.</p>
                    </div>
                  
                  <div  v-if="(tempGroupData.selectedItem || tempContactData.selectedItem)"  class="info-message" style="text-align: center;">
                  <span class="font-weight-medium">Selected: </span><span v-if="tempGroupData.selectedItem">{{ tempGroupData.selectedItem }} {{tempGroupData.selectedItem > 1 ?'Groups' : 'Group' }} </span><span v-if="tempContactData.selectedItem"><span v-if="tempGroupData.selectedItem"> and </span> {{ tempContactData.selectedItem }} {{tempContactData.selectedItem > 1 ?'Contacts' : 'Contact' }} </span> will hear this geofence message.
                  </div>
                </div>
                </div>
              </div>
              <v-window v-model="tab" class="window-wrapper">
                <v-window-item :value="1">
                  <v-container fluid>
                  <ContactListForMessages 
                      ref="contacttab"
                      :selectedMember="selectedContactIds"
                      :isSelectAllContacts="isSelectAllContacts"
                      :tempGroupData="tempGroupData"
                      @search-open="onSearchStateChange"
                      @contact-selected="onContactSelected" />
                    </v-container>
                </v-window-item>
                <v-window-item :value="2">
                  <v-container fluid>
                    <GroupListForMessages 
                    ref="groupList" 
                    :isSelectAllGroups="isSelectAllGroups" 
                    :selectedGroupsIds="selectedGroupsIds"  
                    :groupTypesList="groupTypesList"
                    @search-open="onSearchStateChange"
                    @groups-selected="onGroupSelected" />
                  </v-container>
                </v-window-item>
                
              </v-window>
                
            
            </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              class="close-btn text-btn btn"
              variant="text"
              @click="onCloseWithoutSave"
            >
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              color="blue-darken-1"
              variant="text"
              :disabled="!enableSaveButton"
              @click="onSaveSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
import { ref, computed } from 'vue';
import GroupListForMessages from "@/components/group/GroupListForMessages.vue";
import ContactListForMessages from "@/components/group/ContactListForMessages.vue";
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    GroupListForMessages,
    ContactListForMessages
  },
  props: {
    showSettings: Boolean,
    selectedGroupsIds: Array,
    isSelectAllGroups: Boolean,
    groupTypesList: Array,
    selectedContactIds: Array,
    isSelectAllContacts: Boolean
  },
  data() {
    return {
      enableSaveButton: true,
      data: {},
      tempGroupData:[],
      tempContactData:[],
      tab: 2,
      activeTab: "member",
      listSearchOpen:false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.showSettings;
      },
      set(value) {
        this.$emit('closed', value);
      }
    }
  },
  methods: {
    onSearchStateChange(state){
      this.listSearchOpen = state;
    },
    onGroupSelected(selection) {
      this.tempGroupData = selection;
      this.enableSaveButton = this.tempGroupData.selectedItem || this.tempContactData.selectedItem;
      this.data = {'selectedGroups':this.tempGroupData, 'selectedContacts':this.tempContactData};
    },
    onContactSelected(selection){
      //alert("onContactSelected")
      this.tempContactData = selection;
      this.enableSaveButton = this.tempGroupData.selectedItem || this.tempContactData.selectedItem;
      this.data = {'selectedGroups':this.tempGroupData, 'selectedContacts':this.tempContactData};
    },
    onSaveSettings() {
      this.$emit('group-selection-saved', this.data);
      this.dialog = false;
    },
    onCloseWithoutSave() {
      this.dialog = false;
    }
  },
  watch : {
    dialog: function (newVal, oldVal) {
      //set the default values on window open to handle mounting problem
      if (newVal){
        this.tempContactData.selectedItem = this.selectedContactIds.length;
        //this.tempContactData.checkedContacts = this.selectedContactIds.map((e)=> {return {"UserID":e}});
        this.tempContactData.checkedContacts = this.selectedContactIds.map(e=> e);
        this.tempContactData.isSelectAll = this.isSelectAllContacts;
        this.tempContactData.uncheckedContacts = [];

        this.tempGroupData.selectedItem = this.selectedGroupsIds.length;
        this.tempGroupData.checkedItems = this.selectedGroupsIds.map(e=> e);
        this.tempGroupData.isSelectAll = this.isSelectAllGroups;
        this.tempGroupData.uncheckedItems = [];

        this.data = {'selectedGroups':this.tempGroupData, 'selectedContacts':this.tempContactData};
        this.enableSaveButton = this.tempGroupData.selectedItem || this.tempContactData.selectedItem;

        // Set the initial tab value based on the props
        if (this.selectedGroupsIds.length > 0 && this.selectedContactIds.length > 0) {
          this.tab = 2;  // Both Groups and Contacts selected, open Groups tab
        } else if (this.selectedContactIds.length > 0) {
          this.tab = 1;  // Only Contacts selected, open Contacts tab
        } else if (this.selectedGroupsIds.length > 0) {
          this.tab = 2;  // Only Groups selected, open Groups tab
        }

      }
    }
  },
  mounted(){
    this.tempContactData = {
      
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      router,
      route
    };
  }
};
</script>


<style lang="scss" scoped>
.window-wrapper {
  height: 677px;
}
.group_member_dialog {
 
  font-size: 16px;
  line-height: 1;
}
.group-member {
  &__list {
    padding-top: 16px;
    padding-left: 178px;
    padding-right: 178px;
    ::v-deep {
      .v-slide-group {
        margin-bottom: 0.5em;
        justify-content: center;
        &__container {
          flex: none;
        }
      }
      .v-btn--variant-text .v-btn__overlay {
        background: #fff !important;
      }
    }
  }
  &__count {
    position: relative;
    top: 25px;
    left: 15px;
    color: #000;
    font-weight: 500;
    display: inline-block !important;
  }

  &__dialog {
  }
}
::v-deep {
  .v-tabs {
    height: 50px;
  }
  .v-pagination__list {
    margin: 0 !important;
    padding: 0 !important;
  }
}
 
::v-deep {
  .custom-btn {
    .v-btn__content {
      color: #000 !important;
      padding: 0px !important;
      font-size: 16px;
    }
    &.v-tabs {
      height: 30px;
    }
    .v-tab__slider {
      background-color: #000000 !important;
      bottom: -5px;
      height: 4px;
    }
   
   .v-toolbar-title .v-toolbar-title__placeholder{
        color: #000;
        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: .01em;
   }
   .range-slider-tow{
        border-bottom: 1px solid #BDBDBD;
   }
   .dialog-title{
        font-size: 16px !important;
        font-weight: 500 !important;
        color:#000;
   }
    .custom-message-settings .v-card-text {
        padding: 0px 0px 0px !important;
    }
    .v-tab.v-tab.v-btn {
      margin-top: 0px !important;
      color: #000 !important;
    }
    .v-btn {
      &:hover {
        .v-tab__slider {
          opacity: 1;
        }
      }
    }
  }
}
.search-box2 {
  ::v-deep {
    .v-field__outline,
    .v-input__details {
      display: none !important;
    }
  }
}
::v-deep {
  .selected_item {
    background: #eaf2fc;
  }
  
  .remove-confirm {
    .hover_item:not(.member_selected):not(.remove_selected) {
      &:hover {
        background: #fff;
      }
      .remove_outline_hover {
        display: none;
      }
      .remove_outline {
        display: block;
      }
    }
  }
   
  .remove_selected {
    background: #f5e0ab;
    opacity: 1;
    &:hover {
      background: #f5e0ab;
    }
    .remove_outline_hover {
      display: block;
    }
    .remove_outline {
      display: none;
    }
  }
}
.pagination {
  margin-left: -9px;
}
.detail_tab {
  width: 400px;
}
.action_btn_clr {
  color: #4783e6;
  text-transform: none !important;
}
.selected-member-wrapper {
  top: 4px;
  margin: 0 4px;
}
.selected_member_count {
  border-radius: 10px;
  color: #000;
  width: 100%;
  background: #f2f2f2;
  margin-bottom: 0px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 17px;
  padding-bottom: 17px;
  &.attention-required {
    background-color: #F0D3D3;
  }
}
</style>